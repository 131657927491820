import React from "react"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Home({ location }) {
  return (
    <Layout location={location}>
      <Hero />
    </Layout>
  )
}

export const Head = () => <Seo title="Acasa" />
