import * as React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Nav from "./nav"
import logo from "../images/icon.png"
import { routes } from "../utils"
export default function Header({ siteTitle, location }) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <nav className="bg-slate-50">
        <div className="mx-auto max-w-7xl px-2">
          <div className="relative flex h-20 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                aria-expanded="false"
                aria-controls="mobile-menu"
                onClick={() => setOpen(!open)}
                className="inline-flex items-center justify-center rounded-md p-2 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900"
              >
                <span className="sr-only">Meniu</span>
                <svg
                  className={classNames(
                    "h-6 w-6 block",
                    open ? "hidden" : "block"
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                <svg
                  className={classNames("h-6 w-6", open ? "block" : "hidden")}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-center sm:justify-between">
              <div className="flex flex-shrink-0 items-center">
                <a href={routes.home.path}>
                  <img src={logo} className="block h-12 w-auto mb-0" />
                </a>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4 py-5 items-center">
                  <Nav location={location} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="mobile-menu"
          className={classNames("sm:hidden", open ? "block" : "hidden")}
        >
          <div className="space-y-1 px-2 pt-2 pb-3">
            <Nav location={location} />
          </div>
        </div>
      </nav>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
