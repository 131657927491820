/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../styles/layout.css"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        location={location}
        siteTitle={data.site.siteMetadata?.title || `Title`}
      />
      <div>
        <main
          className="mx-auto max-w-7xl px-2"
          style={{ minHeight: "calc(100vh - 116px)" }}
        >
          {children}
        </main>
        <footer className="bg-slate-50 py-2">
          <div className="mx-auto max-w-7xl px-2 text-sm flex justify-between">
            <span>
              © {new Date().getFullYear()} <strong>Ceicos's Van</strong>
            </span>
            <a
              className="text-black no-underline hover:text-green-600"
              href="tel:+40757548453"
            >
              +40 757 548 453
            </a>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
