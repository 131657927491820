import React from "react"
import { routes } from "../utils"
import { Link } from "gatsby"
import classNames from "classnames"

export default function Nav({ location }) {
  return (
    <>
      {Object.values(routes).map(({ path, title }, key) => (
        <Link
          to={path}
          aria-current="page"
          key={`nav-link-${key}`}
          className={classNames(
            path === location?.pathname
              ? "bg-gray-100 text-gray-700"
              : "text-gray-500",
            "hover:bg-gray-100 hover:text-gray-900",
            "block px-3 py-2 no-underline rounded-md text-base font-medium"
          )}
        >
          {title}
        </Link>
      ))}
    </>
  )
}
