export const routes = {
  home: {
    title: "Acasa",
    description: "",
    path: "/",
  },
  // menu: {
  //   title: "Menu",
  //   description: "",
  //   path: "/menu",
  // },
  // team: {
  //   title: "Team",
  //   description: "",
  //   path: "/team",
  // },
  // about: {
  //   title: "About",
  //   description: "",
  //   path: "/about",
  // },
}
